import React from 'react'
import HeroImage1 from '../images/hero-images/banner-bespoke.jpeg'
import HeroImage2 from '../images/hero-images/banner-customer-delight.jpeg'
import HeroImage3 from '../images/hero-images/banner-horses-mouth.jpeg'
import HeroImage4 from '../images/hero-images/banner-live-status-tracking.jpeg'
import HeroImage5 from '../images/hero-images/banner-on-any-device.jpeg'

const slides = [

    {
        id: 2,
        image: HeroImage2,
        mainText: 'A Platform  <br />  <span>For Performance</span>',
        subText: 'Constant focus on feedback will enable a culture for performance.  and customer delight. On the ball. ',
        action: {
            'link': '/'
        }
    },
    {
        id: 3,
        image: HeroImage3,
        mainText: 'From <br /><span> The Horses Mouth</span>',
        subText: 'Get raw feedback direct from the source on your performance.This will enable you to get a first hand understanding on the ground situation',
        action: {
            'link': '/'
        }
    },
    {
        id:4,
        image: HeroImage4,
        mainText: 'Real-time   <br /> <span>Monitoring / Action</span>',
        subText: 'Dashboard provides you with real-time stats for prompt corrective action. KPIs and parameters are tracked for deviations.',
        action: {
            'link': '/'
        }
    },
    {
        id: 5,
        image: HeroImage1,
    
        mainText: 'Bespoke<span> Surveys</span>',
        subText: 'You design and execute your surveys.Pulse is fully tailored to your specific needs.'
    },
    {
        id:1,
        image: HeroImage5,
        mainText: 'Access From      <br /> <span>Any Device</span>',
        subText: 'Mobile - Tab - Laptop - Desktop. Access from any Internet Enabled Platform. No client end software is required',
        action: {
            'link': '/'
        }
    }
]

const HeroSlides = () => {

    return (
        <div className="hero-wrap" id="sec1" data-scrollax-parent="true">
            <div className="slider-carousel-wrap full-height fullscreen-slider-wrap">
                <div className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick='{"autoplay": true, "autoplaySpeed": 4000 , "pauseOnHover": false}'>
                {slides.map(slide => (
                    <div key={slide.image} className="fullscreen-slider-item full-height fl-wrap">
                        <div className="bg par-elem hero-bg"  data-bg={slide.image}></div>
                        <div className="overlay"></div>
                        <div className="half-hero-wrap">
                            <h1 dangerouslySetInnerHTML={{ __html: slide.mainText }}></h1>
                            <h4>{slide.subText}</h4>
                            <div className="clearfix"></div>
                            {/* <a href={slide.action.link} className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's Start</a> */}
                        </div>
                    </div>
                ))}
                </div>
                <div className="sp-cont   sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
                <div className="sp-cont   sp-cont-next"><i className="fal fa-arrow-right"></i></div>
                <div className="fullscreenslider-counter"></div>
            </div>
            <div className="hero-decor-numb">
                <span>6.9149106  </span><span>79.9105932 </span>
                <a href="https://g.page/PragICTS?share" target="_blank" className="hero-decor-numb-tooltip">Based In Colombo, Sri Lanka</a>
            </div>
        </div>
    )
}

export default HeroSlides
