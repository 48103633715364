import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import HeroImage1 from '../images/overview/pulse_login.jpeg'
import HeroImage2 from '../images/overview/pulse_dashboard.jpeg'
import HeroImage3 from '../images/overview/pulse_customer_maintenance.jpeg'
import HeroImage4 from '../images/overview/pulse_customer_greeting_2.jpeg'
import HeroImage5 from '../images/overview/pulse_customer_greeting.jpeg'
import HeroImage6 from '../images/overview/pulse_answer_bank.jpeg'

const slides = [

    {
        id: 2,
        image: HeroImage2,
        mainText: 'A Platform  <br />  <span>For Performance</span>',
        subText: 'Constant focus on feedback will enable a culture for performance.  and customer delight. On the ball. ',
        action: {
            'link': '/'
        }
    },
    {
        id: 3,
        image: HeroImage3,
        mainText: 'From <br /><span> The Horses Mouth</span>',
        subText: 'Get raw feedback direct from the source on your performance.This will enable you to get a first hand understanding on the ground situation',
        action: {
            'link': '/'
        }
    },
    {
        id:4,
        image: HeroImage4,
        mainText: 'Real-time   <br /> <span>Monitoring / Action</span>',
        subText: 'Dashboard provides you with real-time stats for prompt corrective action. KPIs and parameters are tracked for deviations.',
        action: {
            'link': '/'
        }
    },
    {
        id: 5,
        image: HeroImage1,
    
        mainText: 'Bespoke<span> Surveys</span>',
        subText: 'You design and execute your surveys.Pulse is fully tailored to your specific needs.'
    },
    {
        id:1,
        image: HeroImage5,
        mainText: 'Access From      <br /> <span>Any Device</span>',
        subText: 'Mobile - Tab - Laptop - Desktop. Access from any Internet Enabled Platform. No client end software is required',
        action: {
            'link': '/'
        }
    },
    {
        id:1,
        image: HeroImage6,
        mainText: 'Access From      <br /> <span>Any Device</span>',
        subText: 'Mobile - Tab - Laptop - Desktop. Access from any Internet Enabled Platform. No client end software is required',
        action: {
            'link': '/'
        }
    }
]

const HomeAbout = () => {

    return (
        <div>

        <section data-scrollax-parent="true" id="overview">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Overview<span>//</span></div>
                <div className="container">
                <div className="row">
                    
                    <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2> <span>Expediting </span>  Business Outcomes</h2>
                            <p>In this day and age a single online negative comment can spark an uncontrollable negative outrage and create a situation for the competition to capitalize on.</p>
                            <p>Recovery in most instances will be tainted with permanent scars. Pulse provides you with a comprehensive feedback platform to keep a tab on your performance from the relevant stake holders. Provides you with a live dashboard for prompt corrective action.</p>
                            <p>This it self can form the foundation for enabling a culture for performance and customer delight.</p>
                           
                           
                           
                            {/* <a href="portfolio.html" className="btn float-btn flat-btn color-btn">My Portfolio</a> */}
                        </div>
                    </div>
                    <div className="col-12">
                    <div className="hero-wrap-1" id="sec1" data-scrollax-parent="true">
            <div className="slider-carousel-wrap full-height fullscreen-slider-wrap">
                <div className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick='{"autoplay": true, "autoplaySpeed": 4000 , "pauseOnHover": false}'>
                {slides.map(slide => (
                    <div key={slide.image} className="fullscreen-slider-item full-height fl-wrap">
                        <div className="bg par-elem hero-bg"  data-bg={slide.image}></div>
                      
                        <div className="half-hero-wrap">
                          
                          
                           
                            {/* <a href={slide.action.link} className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's Start</a> */}
                        </div>
                    </div>
                ))}
                </div>
                <div className="sp-cont   sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
                <div className="sp-cont   sp-cont-next"><i className="fal fa-arrow-right"></i></div>
                <div className="fullscreenslider-counter"></div>
            </div>
           
        </div>
                    </div>
                   
                    
                </div>
                
            </div>

           

            <div className="sec-lines"></div>
        </section>
        

        </div>
        
    )
}

export default HomeAbout
