import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'

const HomeStats = () => {

    return (
        <section style={{ paddingTop: '0px' }} id="highlights" data-scrollax-parent="true">
        <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Highlights</div>
        <div className="container">
            <div className="section-title fl-wrap">
                <h3>Our Highlights</h3>
                <h2> Pulse <span>Highlights</span></h2>
            </div>
            <div className="process-wrap fl-wrap">
                <ul>
                    <li>
                        <div className="time-line-icon">
                            <i className="fab pragicts-analysis-icon"></i>
                        </div>
                        <h4>100% Customizable</h4>
                        <div className="process-details">
                            {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                            <p>Pulse platform can be customized to meet your specific company and industry needs (functionality, features, dashboard, reports, etc.).</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">01.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-requirements-articulation-icon"></i>
                        </div>
                        <h4>100% Web Browser Based</h4>
                        <div className="process-details">
                            {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                            <p>No client end software is required. A full HTML standards compliant web browser based system which is provides for centralized deployment.</p>                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">02.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-design-icon"></i>
                        </div>
                        <h4>100% Responsive</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                    <p>The UI scales for effective access on any Internet Enabled Device (phone, tab, laptop, desktop, HDTV,etc.) both on landscape and portrait modes.</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">03.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-support-icon"></i>
                        </div>
                        <h4>100% Open Source</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Engineered on a proven Open Source Stack for scalability, security, extensibility and deploy-ability. The Pulse platform can be deployed on most platform configurations.</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">04.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-support-1-icon"></i>
                        </div>
                        <h4>Live Dashboard</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Dashboard enables you to track your KPIs and key parameters for effective analysis and decision making. The most recent survey is always displayed for the latest update.</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">05.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-support-2-icon"></i>
                        </div>
                        <h4>100% Customizable Reports</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Have the reports to need to effectively fullfill your tasks. Reports are tailored at implementation to meet your specific needs.</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">06.</span>
                    </li>

                
                    
                </ul>
            </div>
            {/* <div className="fl-wrap mar-top">
                <div className="srv-link-text">
                    <h4>Need more info ? Visit my services page :  </h4>
                    <a href="services.html" className="btn float-btn flat-btn color-btn">My Services</a>
                </div>
            </div> */}
        </div>
        <div className="bg-parallax-module" data-position-top="90"  data-position-left="30" data-scrollax="properties: { translateY: '-150px' }"></div>
        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
        <div className="sec-lines">
            <div className="container full-height"><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div></div>
        </div>
    </section>
    )
}

export default HomeStats
